@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  transition: all 300ms ease;
}

html{
  scroll-behavior: smooth;
}

li{
  list-style-type: none;
}

p{
  line-height: 1.5;
  font-size: 16px;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
}

body{
  background-color: rgb(245, 245, 245);
  transition: all 300ms ease;
}

section{
  display: block;
}

.section__title{
  text-align: center;
  font-size: 48px;
  margin-bottom: 60px;
}

input, textarea{
  width: 100%;
  background-color: #242424;
  color: white;
  outline: none;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 40px;
  transition: all 300ms ease;
}

textarea{
  resize: vertical;
  height: 100px;
  margin-top: 8px;
}

label{
  font-size: 14px;
  font-weight: bold;
}

input:hover, textarea:hover{
  border-color: white;
}

input:focus, textarea:focus{
  border-color: rgb(53, 111, 236);
}

.container{
  padding: 50px 0;
  width: 100%;
}

.row{
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 12px;
}

.click{
  transition: all 300ms ease;
  cursor: pointer;
}

.click:hover{
  transform: scale(1.1);
}

.click:active{
  transform: scale(0.8);
}

.title{
  font-size: 120px;
  margin-bottom: 12px;
  text-align: left;
  line-height: 1;
  text-align: center;
}

.blue{
  color: rgb(53, 111, 236) !important;
}

.link__hover-effect{
  position: relative;
}

.link__hover-effect:after{
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  transition: all 300ms ease;
}

.link__hover-effect:hover:after{
  left: 0;
  width: 100%;
}

.link__hover-effect--white:after{
  background-color: white;
}

.link__hover-effect--black:after{
  background-color: black;
}

.scroll{
  opacity: 1;
  visibility: visible;
  transform: all 600ms 600ms;
}

.dark-theme .title,
.dark-theme .section__title,
.dark-theme .nav__link--anchor,
.dark-theme .header__para,
.dark-theme .about__para,
.dark-theme .tech__name{
  color: white;
}

.dark-theme section {
  background-color: #242424;
}

.dark-theme #personal-logo {
  filter: none;
}

.dark-theme .scroll__icon {
  border-color: rgb(53, 111, 236);
}

.dark-theme .scroll__icon:after,
.dark-theme .link__hover-effect--black:after {
  background-color: white;
}

.dark-theme .form__name,
.dark-theme .form__email,
.dark-theme .form__message{
  border: 2px solid #242424;
  color: #242424;
}

.dark-theme #contact__form{
  background-color: rgb(245, 245, 245);
}

.dark-theme .form__submit:hover{
  border-color: #242424;
  color: #242424;
}

.dark-theme .form__sent{
  color: #242424;
}

/* NAV */

.container__nav{
  padding: 0;
}

.nav__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100px;
  padding: 0 20px;
}

#personal-logo{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid #242424 4px;
}

.nav__link--list{
  display: flex;
}

.nav__link{
  margin: 0 12px;
}

.nav__link--anchor{
  text-decoration: none;
  font-size: 20px;
  color: #242424;
  font-weight: 700;
}


/* LANDING PAGE */

#landing{
  min-height: 100vh;
}

.header__content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1000px;
  padding: 0 30px;
}

.subtitle{
  font-size: 40px;
  text-align: center;
}

.header__para{
  font-size: 24px;
  line-height: 2;
  max-width: 450px;
}

.social__list{
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.social__link{
  background-color: rgb(53, 111, 236);
  color: white;
  padding: 6px;
  width: 32px;
  height: 32px;
  font-size: 14px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center ;
  border-radius: 50%;
}

.scroll{
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
}

.scroll__icon{
  width: 25px;
  height: 40px;
  border: 2px solid #242424;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll__icon:after{
  content: "";
  width: 4px;
  height: 6px;
  background-color: rgb(53, 111, 236);
  border-radius: 2px;
  animation: scroll 1000ms infinite alternate-reverse;
}

@keyframes scroll{
  0%{
      transform: translateY(6px);
  }

  100%{
      transform: translateY(-6px);
  }
}

/* ABOUT */

.about__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.about__img{
  height: 300px;
  width: 295px;
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.75);
}

.about__para{
  font-size: 24px;
  line-height: 2;
  max-width: 450px;
}

/* PROJECTS  */

.project{
  margin-bottom: 135px;
}

.project__video{
  width: 100%;
  transition: all 600ms ease;
}

.project:hover .project__img{
  transform: scale(1.07);
  filter: blur(5px);
}

.project__wrapper{
  position: relative;
  border-radius: 20px;
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.45);
  overflow: hidden;
}

.project__wrapper:hover .project__description{
  opacity: 1;
  transform: translateY(-50%);
}

.project__wrapper:hover .project__img{
  transform: scale(1.07);
  filter: blur(5px);
}

.project__description{
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(100%);
  color: white;
  max-width: 550px;
  opacity: 0;
  transition: opacity 300ms, transform 450ms ease;
}

.project__wrapper:hover .project__wrapper--bg{
  opacity: 0.7;
}

.project__wrapper--bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
}

.project__description--title{
  font-size: 40px;
}

.project__description--para{
  margin: 16px 0;
}

.project__description--sub-title{
  margin-top: 8px;
}

/* CONTACT  */

.contact__wrapper{
  display: flex;
  margin: 50px auto;
  max-width: 600px;
}

.form__name, .form__email{
  height: 46px;
  align-items: center;
  background: transparent;
  border: 2px solid #ececec;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 5px 15px;
  resize: none;
  transition: all .2s ease 0s;
  width: 100%;
}

.form__message{
  height: 200px;
  align-items: center;
  background: transparent;
  border: 2px solid #ececec;
  border-radius: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 5px 15px;
  resize: none;
  transition: all .2s ease 0s;
  width: 100%;
}

#contact__form{
  background-color: #242424;
  border-radius: 20px;
  box-shadow: 0 3px 10px -3px rgb(0, 0, 0 / 54%);
  display: grid;
  justify-items: center;
  padding: 20px;
  width: 100%;
  color: white;
}

.form__submit{
  background-color: rgb(53, 111, 236);
  border: 2px solid rgb(53, 111, 236);
  color: white;
  font-weight: 700;
  width: 100%;
  max-width: 240px;
  padding: 12px 24px ;
  font-size: 20px;
  transition: all 300ms ease;
  margin-top: 16px;
}

.form__submit:hover{
  border-color: white;
  background-color: transparent;
}

.form__submit:active{
  border-color: rgb(53, 111, 236);
  color: rgb(53, 111, 236);
}

.form__sent--wrapper{
  padding: 10px;
}

/* FOOTER */

footer{
  position: relative;
  background-color: #242424;
  display: flex;
  padding: 6% 0;
}

.footer__row{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__logo--img{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid #242424 4px;
}

.footer__social--list{
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  margin: 28px 0;
}

.footer__social--link, 
.footer__copyright{
  color: white;
}

.footer__logo--popper {
  position: absolute;
  right: 0;
  top: 16px;
  font-weight: 700;
  opacity: 0;
  transition: all 300ms ease;
  color: white;
}

.footer__anchor {
  position: relative;
}

.footer__anchor:hover .footer__logo--popper {
  transform: translateX(60px);
  opacity: 1;
}

/* MEDIA QUERIES */

@media(max-width: 768px){
  .title{
      font-size: 80px;
  }

  .about__para{
      font-size: 20px;
      margin-top: 24px;
  }

  .about__wrapper{
    flex-direction: column;
  }
}

@media(max-width: 480px){
  .title{
      font-size: 44px;
  }

  .subtitle{
    font-size: 24px;
  }

  .about__para {
      font-size: 18px;
  }

  .nav__link:first-child {
      display: none;
  }

  .project__description--para {
      display: none;
  }

  .project__description {
      left: 0;
      padding: 0;
      width: 100%;
      text-align: center;
  }

  .project__description--sub-title {
      margin-bottom: 8px;
  }

  .project__links--wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}